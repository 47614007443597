
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingIcon from '../components/icons/LoadingIcon';

export default function Stats() {
    const [stats, setStats] = useState([]);

    useEffect(() => {
        const stats_api = {
            method: 'GET',
            url: `${process.env.REACT_APP_FLEXAPI_URL}/stats/count`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        };
        // Effectuez la requête HTTP avec la nouvelle valeur de q
        axios.request(stats_api)
            .then((response) => {
                setStats(response.data);
            })
            .catch((error) => {
                console.error('Error fetching episodes:', error);
            });
    }, []);
  
    const stats_card = [
        { name: 'Nombres d\'épisodes', stat: stats.episode_count },
        { name: 'Nombres de saisons', stat: stats.season_count },
        { name: 'Nombres d\'animes', stat: stats.anime_count },
    ]

    return (
      <div>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats_card.map((item) => (
            <div key={item.name} className="px-4 py-5 glass shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold text-bb">{item.stat || <LoadingIcon /> }</dd>
            </div>
          ))}
        </dl>
      </div>
    )
  }
  