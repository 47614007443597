import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Error from '../components/Error';
import LoadingIcon from '../components/icons/LoadingIcon';

function EpisodeCard({ id, anime_title, season_title, thumbnail, episode_number }) {
  return (
    <div className="xs:w-1/4 lg:w-1/3 w-full">
      <div className="d-block ma-thumbnail">
        <div className="Last-ep-holder">
          <div className="overlay last-ep-dark">
            <a className="play" href={`/view/${id}`}>
              <svg viewBox="0 0 24 24">
                <path d="M8,5.14V19.14L19,12.14L8,5.14Z"></path>
              </svg>
            </a>
          </div>
          <img src={thumbnail} alt={`${anime_title}- InuAnime, le meilleur site de streaming anime - VOSTFR, VF`} />
        </div>
        <div className="text">
          <a className="title" href={`/view/${id}`}>
            <div className="content-title">
              <span>Episode {episode_number}</span>
              <p className="content-subtitle">{season_title}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

// ... (import statements)

export default function EpisodeSelect(props) {
  const { anime_id, season_id } = props;
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Nouvel état de chargement

  const episodes_endpoint = useMemo(
    () => ({
      method: 'GET',
      url: `${process.env.REACT_APP_FLEXAPI_URL}/episodes/anime`,
      params: {
        anime_id: anime_id,
        season_id: season_id,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    [anime_id, season_id]
  );

  useEffect(() => {
    // Définir isLoading à true au début de la requête
    setIsLoading(true);

    // Lancer la requête avec un délai de 0.1 seconde
    const timeoutId = setTimeout(() => {
      axios
        .request(episodes_endpoint)
        .then((response) => {
          setEpisodes(response.data);
        })
        .catch((error) => {
          console.error('Error fetching episodes:', error);
        })
        .finally(() => {
          // Quelle que soit la fin de la requête, définir isLoading à false
          setIsLoading(false);
        });
    }, 100); // 100 millisecondes

    // Nettoyer le timeout si le composant est démonté avant que le délai ne soit écoulé
    return () => clearTimeout(timeoutId);
  }, [episodes_endpoint]);

  return (
    <div>
      <section className="epidode">
        <div className="">
          <h1 className="text-2xl font-bold mt-10">
            Episodes de {props.season_title}
          </h1>

          <div className="c-feed-anime c-feed-anime__divider"></div>
          <section className="LASTep" id="episodes">
            {isLoading ? ( // Si isLoading est vrai, afficher le message de chargement personnalisé
              <>
                <div class="flex items-center justify-center w-full h-full">
                  <div class="flex justify-center items-center space-x-1 text-sm text-gray-200">
                    <LoadingIcon />
                    <div>Chargement ...</div>
                  </div>
                </div>
              </>
            ) : episodes.length === 0 ? (
              <Error
                title={props.season_title + " n'est pas disponible"}
                text="La saison n'est peut-être pas encore sortie"
                button_text="Retour à l'accueil"
                button_link="/"
              />
            ) : (
              episodes.map((episode) => (
                <EpisodeCard key={episode.id} {...episode} />
              ))
            )}
          </section>

          <section className="button-page-anime"></section>
        </div>
      </section>
    </div>
  );
}

