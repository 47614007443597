import Nav from '../components/Nav'
import Header from '../components/Header'
import EpisodeList from '../components/EpisodeCard'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import DiscordCta from '../components/DiscordCta'

export default function Home() {
    useEffect(() => {
        document.title = `Accueil | InuAnime`;
      }, []);
    return (
        <div>
            <Nav />
            <Header title={`InuAnime`} subtitle="Bienvenue 🌀" nav />
            
            <div className="list-title list-item mx-4 my-10 z-0">
                <h2 className="item-title2 mt-10">Derniers Épisodes Ajoutés</h2>
                <Link to="/search/all/all"> 
                    <span className="see-all mt-10">Tous &gt;</span>
                </Link>
                <div className="c-feed-divider c-feed-divider--odd c-feed-header__divider"><div></div></div>
                <EpisodeList />
                <DiscordCta />
            </div>
        </div>
    )
}


