import React from 'react';
import SearchBar from '../components/SearchBar'
import HeaderBg from '../img/header_bg/header-rain.gif'

export default function Header(self) {
    const headerStyle = {
        backgroundImage: `url(${HeaderBg})`, 
    };

    return (
        <div>
            <header 
                className="LibraryPage-header" 
                style={headerStyle}     
            >
                <h1 className="title drop-shadow-xl">
                    <b>{self.title}</b>
                </h1>
                <h2 className="subtitle drop-shadow-xl" style={{ fontFamily: 'consolas, sans-serif' }}>
                    <span className='p-2'>{self.subtitle}</span>
                </h2>
                {self.nav ? <SearchBar /> : null}
            </header>       
        </div>
    )
}
