import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Dialog, Popover } from '@headlessui/react'
import {
  Bars3Icon,
  SquaresPlusIcon,
  XMarkIcon,
  FilmIcon, 
  TvIcon, 
  HomeIcon,
 // NewspaperIcon, 
} from '@heroicons/react/24/outline'
import Logo from '../img/logo.png'

import TiktokLogo from '../img/icons/tiktok.svg';
import TwitterLogo from '../img/icons/x.svg';
import DiscordLogo from '../img/icons/discord.svg';


const tab = [
  {name: 'Accueil', route: '/', icon: HomeIcon},
  { name: 'VOSTFR', route: '/search/vostfr/all', icon: FilmIcon },
  { name: 'VF', route: '/search/vf/all', icon: TvIcon },
  // { name: 'News',  route: '/news', icon: NewspaperIcon },
  { name: 'A propos', route: '/about', icon: SquaresPlusIcon },
]


function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const currentPage = window.location.pathname;

  return (
    <header className="Iw-header">
      
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">InuAnime</span>
            <img className="h-8 w-auto" src={Logo} alt="InuAnime" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 text-gray-200 hover:text-[#5a66bf]" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">


          {tab.map((item, index) => (
            <Link 
            key={index} 
            to={item.route} 
            className={`${currentPage === item.route ? 'hover:text-[#5a66bf] font-extrabold text-indigo-300' : 'text-gray-400 hover:text-indigo-100'} text-sm font-semibold leading-6 py-2 pr-1`}>
              {item.icon && <item.icon className="w-5 h-5 mr-2 inline-block" />} {item.name}
            </Link>

          ))}
  
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_DISCORD}`} className="text-sm font-semibold leading-6 text-gray-400 hover:text-[#5a66bf]">
            Discord <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto glass-solid px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10" style={{zIndex: '999'}}>
          <div className="flex items-center justify-between">
            <Link to="#" className="-m-1.5 p-1.5">
              <span className="sr-only">InuAnime</span>
              <img
                className="h-8 w-auto"
                src={Logo}
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-200 hover:text-[#5a66bf]"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
         
  

                {tab.map((item, index) => (
                  <Link key={index} to={item.route} className={`${currentPage === item.route ? 'text-[#5a66bf] bg-indigo-500/10 font-extrabold hover:text-indigo-300' : 'text-gray-400 '}  -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7  hover:text-[#5a66bf]`}>
                    {item.icon && <item.icon className="w-5 h-5 mr-2 inline-block" />} {item.name}
                  </Link>
                ))}


              </div>
              <div className="py-6"> 
              <h2 className='text-gray-300 uppercase font-bold text-center'>
                <span className='text-[#5a66bf] font-bold text-lg mr-2'>#</span>
                 Nos réseaux 
              </h2>
                <span className='block'>
                <a
                  target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_DISCORD}`}
                  className="-mx-3 inline-flex rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-400 hover:text-[#5a66bf]"
                > 
                    <img src={DiscordLogo} className='mr-2' alt="" />
                    Discord
                </a>
                </span>
                <span className='block'>
                <a
                  target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_TWITTER}`}
                  className="-mx-4 inline-flex rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-400 hover:text-[#5a66bf]"
                >
                  <img src={TwitterLogo} className='mr-1' alt="" />
                  Twitter
                </a>
                </span>
                <span className='block'>
                  <a
                    target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_TIKTOK}`}
                    className="-mx-4 inline-flex rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-400 hover:text-[#5a66bf]"
                  >
                    <img src={TiktokLogo} className='mr-2' alt="" width='30' height='30'/>
                    Tiktok
                  </a>
                </span>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}


export default Nav;