import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/index.css';
import '../css/App.css';


const last_ep = {
  method: 'GET',
  url: `${process.env.REACT_APP_FLEXAPI_URL}/last/episodes`,
  params: {
    limit: 8,
  },
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
};




function EpisodeCard(props) {
  function dateDiff(dateString) {
    // Convertir la chaîne en objet Date
    const dateObject = new Date(dateString);
  
    // Obtenir la date d'aujourd'hui
    const today = new Date();
  
    // Calculer la différence en millisecondes
    const dateMsDiff = today - dateObject;
  
    // Convertir la différence en jours
    const dateDiff = Math.floor(dateMsDiff / (1000 * 60 * 60 * 24))+1;
  
    return dateDiff;
  }
  
  
  return (
    <div className="lg:w-1/4 w-1/2 z-10">
      <div className="d-block ma-thumbnail">
        <div className="Last-ep-holder">
          <div className="overlay last-ep-dark">
            <span className="last-ep-time">Il y'a {dateDiff(props.date_time_added)} jours </span>
            <a className="play" href={"/view/"+props.id }>
              <svg viewBox="0 0 24 24">
                <path d="M8,5.14V19.14L19,12.14L8,5.14Z"></path>
              </svg>
            </a>
          </div>
          <img src={props.thumbnail} alt={props.season_title} />
        </div>
        <div className="text">
          <a className="title" href={"/view/"+props.id}>
            <div className="content-title">
              <span>{props.anime_title} <span className='text-bb font-bold nodarkmode'>- Ep. {props.episode_number}</span> </span>
              <p className="content-subtitle">{props.season_title} </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

function EpisodeList() {
  
  const [episodes, setEpisodes] = useState([]);
  useEffect(() => {
    axios.request(last_ep)
    .then((response) => {
      setEpisodes(response.data);
    })
    .catch((error) => {
      console.error('Error fetching episodes:', error);
      
    });
  
  }, []);

  return (
    <section className="LASTep pr-2 py-5">
      {episodes.map((episode) => (
        <EpisodeCard key={episode.id} {...episode} />
      ))}
    </section>
  );
}

export default EpisodeList;
