import React from 'react';

import TiktokLogo from '../img/icons/tiktok.svg';
import TwitterLogo from '../img/icons/x.svg';
import DiscordLogo from '../img/icons/discord.svg';
import Ad from './Ad';

export default function Footer() {
  return (
    <div className="footer-anime">
      <div className="IA-footer">
        <div className="IA-footer-content">
          <div className="text-footer">
            Le site Inu Anime n'héberge aucun fichier vidéo, que ce soit pour du streaming ou du téléchargements. Celui-ci consiste en une indexation de liens vidéos sur différents hébergeurs publiques et connus.
          </div>
        </div>
      </div>

      <div className="IA-Links ">
        <div className='link-item space-x-2'>

          <a className='p-2 rounded-full bg-black hover:border-black border-indigo-400 hover:bg-indigo-400 border-2' target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_DISCORD}>
            <img src={DiscordLogo} width="30" height="30" alt="discord" />
          </a>

          <a className='p-2 rounded-full bg-black hover:border-black border-blue-400 hover:bg-blue-400 border-2' target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_TWITTER}>
            <img src={TwitterLogo} width="30" height="30" alt="x" />
          </a>

          <a className='p-2 rounded-full bg-black hover:border-black border-red-400 hover:bg-red-400 border-2' target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_TIKTOK}>
            <img src={TiktokLogo} width="30" height="30" alt="x" />
          </a>

      </div>
      <Ad />
      </div>

    </div>
  );
};

