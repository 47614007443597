import DiscordImage from '../img/discord_cta.png'
import { Link } from 'react-router-dom'

export default function DiscordCta() {
    return (
      <>
        <div className="mx-auto max-w-7xl py-16 sm:px-6 sm:py-24 lg:px-8">
          <div className="relative isolate overflow-hidden bg-[#101010] px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#5B66C0" />
                </radialGradient>
              </defs>
            </svg>
            <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
              <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">
                Soyez au courant des dernières sorties
                <br />
                <span className="text-indigo-200">Rejoignez notre Discord</span>
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Parfait pour suggérer des animes, être au courant des dernières sorties, des annonces,  et bien plus encore.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <a
                  href={process.env.REACT_APP_DISCORD}
                  className="rounded-md glass px-3.5 py-2.5 text-sm font-semibold text-indigo-200 shadow-sm hover:border-indigo-400 hover:text-indigo-100  border-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Rejoindre le Discord
                </a>
                <Link to={`/about`} className="text-sm font-semibold leading-6 hover:text-indigo-100">
                  Plus d'infos <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
            <div className="relative mt-16 h-80 lg:mt-8">
              <img
                className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                src={DiscordImage}
                alt="Discord Screen"
                width={1824}
                height={1080}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
  