import Nav from '../components/Nav';
import Header from '../components/Header';
import { useEffect } from 'react';
import '../css/App.css'
import '../css/index.css'

import Stats from '../components/Stats';

export default function About() {
    useEffect(() => {
        document.title = `A propos | InuAnime`;
      }, []);
    return(
        <>
        <Nav />
        <Header title="A propos" subtitle="Pourquoi InuAnime?" />


        <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-10" >

            <h1 className="md:text-3xl text-xl font-extrabold mb-4">
                <span className="text-bb"># </span>
                InuAnime, c'est quoi?
            </h1>
            <Stats />

            <p className="text-lg text-gray-400 mt-10">

                L'idée du site InuAnime nous est venu car nous en avions assez des sites de streaming d'anime payants ou remplis de pubs douteuses où ne pas avoir de virus relève du défi. <br /><br />
                Notre but est de vous proposer un site de streaming d'anime gratuit, sans pub et sans inscription. <br />
                Fini les pubs douteuses et les sites qui vous demandent de vous inscrire pour pouvoir regarder un épisode. <br /><br />
                Pas de concession chez Inu, nous essayons de proposer la meilleure interface (via notamment notre lecteur vidéo InuViewer) et la meilleure qualité vidéo pour s'approcher au maximum des services
                payants traditionnels. 
            </p>


            <h1 className="md:text-3xl text-xl font-extrabold mt-10 mb-4">
                <span className="text-bb"># </span>
                Nos réseaux sociaux
            </h1>
            <p className="text-lg text-gray-400">
                Pour garder contact avec notre commuauté, nous avons crée un <a className='text-white hover:text-indigo-500' target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_DISCORD}`} >Discord</a> pour communiquer et un Twitter/X pour vous tenir informé des prochaines sorties d'animes.
                <iframe 
                title='Discord' 
                className='mt-4' 
                src="https://discord.com/widget?id=1173503812351311912&theme=dark" 
                width="100%" 
                height="350" 
                allowTransparency="true" 
                frameBorder="0" 
                sandBox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                </iframe>
            </p>

            <h1 className="md:text-3xl text-xl font-extrabold mt-10 mb-4">
                <span className="text-bb"># </span>
                Informations légales
            </h1>
            <p className="text-lg text-gray-400">
                Nous rappelons que InuAnime n'héberge aucun contenu soumis à des droits d'auteurs, notre site n'est qu'un annuaire de liens vers des sites tiers.  <br />               
            </p>



        </div>

        </>
    )
}