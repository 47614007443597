import ErrorImage from '../img/error.png';

export default function Error(props) {
    return (
            <div className="Wrapper mt-10">
                <div className="wrapper-top">
                    <div className="Proch-Page-Image">
                        <img src={ErrorImage} alt={props.title} />
                    </div>
                    <h1 className="text-sm font-bold text-indigo-500">{props.error}</h1>
                    <h1 className="Proch-Page-Title font-ultrabold">{props.title}</h1>
                    <p className="Proch-Page-Secondary-Title">
                        {props.text}
                    </p>
                    <a href={props.button_link}>
                        <button className="Proch-Page-Button">{props.button_text}</button>
                    </a>
                </div>
            </div>
    )
}