import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Nav from '../components/Nav';
import EpisodeSelect from '../components/EpisodeSelector';
import DiscordCta from '../components/DiscordCta';
import { Helmet } from 'react-helmet';
import Ad from '../components/Ad';


export default function View(){

    const { id } = useParams();
    const [ep, setEp] = useState([]);
    
    const get_ep = useMemo(() => ({
      method: 'GET',
      url: `${process.env.REACT_APP_FLEXAPI_URL}/episodes/item`,
      params: {
        id: id 
      },
      headers: {
        'Content-Type': 'application/json',
      }
    }), [id]);
    
    useEffect(() => {
      axios.request(get_ep)
        .then((response) => {
            setEp(response.data);
        })
        .catch((error) => {
          console.error('Error fetching episodes:', error);
        });
    }, [get_ep]);
    

    const ep_nav = {
        previous: "/view/" + ep.previous_ep_id,
        next: "/view/" + ep.next_ep_id,
        anime: "/anime/" + ep.anime_id
    }

    useEffect(() => {
        document.title = `Anime | InuAnime`;
      }, []);

    // function to decrypt the link
    /*
    function decrypt(cipherText) {
        const bytes  = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_PRIVATE_KEY);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
    };
    */


    return(
        
        <div>
          <Helmet>
            <title>{ep.anime_title + " Episode " + ep.episode_number || "InuAnime"}</title>
            <meta name="description" content={ep.season_title || "Regarde ton anime gratuitement !"} />
            {/* OG tags */}
            <meta property="og:title" content={"InuAnime |" + ep.anime_title + " Episode " + ep.episode_number} />
            <meta property="og:description" content={ep.season_title || "Regarde ton anime gratuitement !"} />
            <meta property="og:url" content={"https://inuanime.tv"} />
            <meta property="og:type" content="website" />
          </Helmet>
          <Nav />
          
          <div className="Ia-watch-container">
            { /* Title 
            <div className="Ia-watch-Title">
                <h2 className="Ia-watch-h2">{ep.anime_title} | <span className='text-gray-400'>{ep.season_title}</span>  - Episode <span className="text-bb font-bold nodarkmode">#{ep.episode_number}</span></h2>
            </div>
            */ }

            
            <div className="button-EP my-2 container">
            {ep.previous_ep_id == null ? (

                <a className="uibutton small with-svg-left hover:border-orange-500 hover:border-2 duration-300" href={ep_nav.anime}>
                    <svg className="a " viewBox="0 0 24 24">
                    <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
                    </svg>
                    <span className='inline-flex'><span className='md:pr-1 pr-5'>Retour </span>  <span className='hidden md:flex'> à l'anime</span></span>
                </a>
                ) : (
                <a className="uibutton small with-svg-left hover:border-[#5A66BF] hover:border-2 duration-300" href={ep_nav.previous}>
                    <svg className="a" viewBox="0 0 24 24">
                    <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
                    </svg>
                    <span className='sm:inline-flex hidden'><span className='hidden md:flex'> Episode</span> <span className='pl-1 md:lowercase'>Précedent </span>  </span>
                </a>
                )}

                { /* Title */}
                <div className="Ia-watch-Title">
                    <h2 className="sm:-mb-2"><span className='text-gray-400'>{ep.season_title}</span> <span className='hidden md:inline-flex'>-  Episode</span>  <span className="text-bb font-bold nodarkmode">#{ep.episode_number}</span></h2>
                </div>
                { /* Title */}

                {ep.next_ep_id == null ? (
                    <a className="uibutton small with-svg-right hover:border-orange-500 hover:border-2 duration-300" href={ep_nav.anime}>
                       <svg className="a" viewBox="0 0 24 24">
                            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
                        </svg>
                      <span className='inline-flex'><span className='md:pr-1 pr-3'>Retour </span>  <span className='hidden md:flex'> à l'anime</span></span>
                  </a>
                    ) : (
                    <a className="uibutton small with-svg-right hover:border-[#5A66BF] hover:border-2 duration-300" href={ep_nav.next}>
                    <span className='sm:inline-flex hidden'><span className='hidden md:flex'> Episode</span> <span className='pl-1 md:lowercase'>Suivant </span>  </span>
                        <svg className="a" viewBox="0 0 24 24">
                            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
                        </svg>
                    </a>
                    )}

            </div>
            


            <div className="Ia-video-Playeer" >

                <iframe 

                title={ep.anime_title + " " + ep.season_title + " Episode " + ep.episode_number}
                src={CryptoJS.AES.decrypt(ep.link || "U2FsdGVkX18KAvyxRga9DMLu4pWdtETIbFuTc4mdgz1Yh36M6+AzjfZFaY67uGcVwpXwpVLoP79ILi/969lTabpt1Q0K34mElaKtrb4GhgKJlp2wWX1PkSypTjt6zDh9UIBhlTs7SZkRkpg7CB7Tav3zrmZevuznnIrJ5AndSkQ+Rt8SVP5ToaGKTIqfkPz251z6DqjI6Ec1JiMPdxg2MA==", process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8)} 
                {...console.log(ep)}
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
                >
                </iframe>
            </div>


            {/* Infos */}
            <section className="md:inline-flex lg:flex-nowrap flex-wrap flex flex-grow 2xl:mx-96 md:mx-40 mx-4 items-start mt-5">
                <div className="mr-3">
                    <Link to={ep_nav.anime}>
                        <img className="ease-in duration-300 hover:opacity-75 border-transparent border-2 hover:border-indigo-500 w-20 h-auto imagepre shadow-xl z-10" src={ep.image} alt={ep.anime_title} />
                    </Link>
                </div>
                <div className="py-4">
                    <Link to={ep_nav.anime}><h3 className="text-lg font-bold ease-in duration-300 hover:text-indigo-500">{ep.anime_title}</h3></Link>
                    <span className=" text-indigo-300">{ep.season_title}</span><br /> 
                    <span className="text-gray-500">Episode {ep.episode_number}</span>
                </div>
            </section>
                <div className="container">
                    <EpisodeSelect anime_id={ep.anime_id} season_id={ep.season_id} season_title={ep.season_title} />
                </div>
            </div>
            <Ad />

            <DiscordCta />

        </div>
    )
}