import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import Error from '../components/Error';
import Header from '../components/Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LoadingIcon from '../components/icons/LoadingIcon';

export default function VostFr() {
  const { q } = useParams();
  const { genre } = useParams();
  const { type } = useParams();

  // Utilisez un état local pour stocker la valeur de q
  const [searchQuery, setSearchQuery] = useState(q);
  const [Genre, setGenre] = useState(genre);
  const [Type, setType] = useState(type);

  console.log(searchQuery, Genre, Type);

  const [animes, setAnimes] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Nouvel état de chargement

  useEffect(() => {
    // Mettez à jour la valeur de q à chaque changement dans l'URL
    setSearchQuery(q);
    setGenre(genre);
    setType(type);

    setIsLoading(true); // Définir isLoading à true au début de la requête

    const search_anime = {
      method: 'GET',
      url: `${process.env.REACT_APP_FLEXAPI_URL}/search/anime`,
      params: {
        q: q,
        genre: genre,
        type: type,
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios
      .request(search_anime)
      .then((response) => {
        setAnimes(response.data);
      })
      .catch((error) => {
        console.error('Error fetching episodes:', error);
      })
      .finally(() => {
        setIsLoading(false); // Quelle que soit la fin de la requête, définir isLoading à false
      });
  }, [q, genre, type]);

  useEffect(() => {
    document.title = `Résultats pour "${q || type.toUpperCase(type)}" | InuAnime`;
  }, [q, type]);

  return (
    <div>
      <Nav />
      {/* Le header contient une <SearchBar />*/}
      <Header
        title="Recherche ton anime"
        subtitle={`Rechercher parmi nos animes ${
          type === 'all' ? '' : 'en ' + type.toUpperCase()
        }`}
        nav
      />

      <div className="container mt-4">
        <div className="list-title">
          <div className="inline-flex items-center">
            <h2 className="item-title">
              <span className="sm:mb-2 mr-2">
                {q === undefined ? (
                  <>
                    Catalogue des Animes
                  </>
                ) : (
                  <>
                    Résultats pour "
                    <span className="text-bb">{q}</span>"
                  </>
                )}
              </span>
              <br className="sm:hidden" />
              <small className="text-gray-400 text-sm align-center font-normal">
                <span className="mx-1">•</span> {animes.length} résultat(s)
                trouvé(s)
              </small>

              <div className="text-sm">
                <span className="text-bb uppercase ">
                  {type === 'all' ? 'VostFR x VF' : type}
                </span>
                <span className="mx-2">•</span>
                <span className="text-bb uppercase">
                  {genre === 'all' ? 'Tous genres' : genre}
                </span>
              </div>
            </h2>
          </div>

          <div className="c-feed-divider c-feed-divider--odd c-feed-header__divider"></div>
        </div>
      </div>

      <div className="Ia-Anime-Page-List pt-4">
        {isLoading ? (
          // Affichez votre indicateur de chargement personnalisé ici
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex justify-center items-center space-x-1 text-sm text-gray-200">
              <LoadingIcon />
              <div>Chargement ...</div>
            </div>
          </div>
        ) : animes.length === 0 ? (
          // Affichez votre composant d'erreur si aucune donnée n'est disponible
          <Error
            title={` ${q || "La recherche"} n'a rien trouvé`}
            text="Peut-être que l'anime n'est pas encore disponible"
            button_text="Retour à l'accueil"
            button_link="/"
          />
        ) : (
          // Affichez le contenu de l'anime si des données sont disponibles
          animes.map((anime) => (
            <a href={`/anime/${anime.id}`} key={anime.id}>
              <div className="Ia-Content-Anime">
                <div className="group Ia-Content-Anime-Image hover:border-indigo-500 border-2 border-transparent ease-in duration-500 mb-1">
                  <img
                    className="group-hover:opacity-50 ease-in duration-500"
                    src={anime.image}
                    alt={`${anime.title} - InuAnime - Streaming HD en VostFR et VF`}
                  />
                </div>
                <div className="Ia-Content-Anime-Title">
                  <span className="span-genre wh0 text-bb2 inline-flex items-center shadow-md my-1 px-2.5 py-0.5 rounded-md text-sm font-medium uppercase mr-2">
                    {anime.type}
                  </span>
                  <span className="span-genre wh0 text-bb2 inline-flex items-center shadow-md my-1 px-2.5 py-0.5 rounded-md text-sm font-medium capitalize">
                    {anime.genre}
                  </span>
                  <br />
                  <span className="Anime-title mt-2">{anime.title}</span>
                </div>
              </div>
            </a>
          ))
        )}
      </div>
    </div>
  );
}
