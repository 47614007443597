import { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import axios from 'axios';
import EpisodeSelect from './EpisodeSelector';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SeasonSelect(props) {
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_FLEXAPI_URL+'/season?anime_id='+props.anime_id);
        setSeasons(response.data);
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    };

    fetchSeasons();
  }, [props.anime_id]);

  return (
    <div className='container'>
      <Tab.Group >
        <Tab.List className={`grid grid-cols-1 md:grid-cols-2 ${seasons.length << 2 ? `lg:grid-cols-${seasons.length}` : "lg:grid-cols-3"} rounded-xl bg-transparent p-1`}>
        {seasons.map((season) => {

            return (
                <Tab
                key={season.id}
                className={({ selected }) =>
                    classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 genreIa wh0 shadow-md bg-gray-800/20 capitalize',
                    'ring-white/60 ring-offset-2 focus:outline-none truncate',
                    selected
                        ? 'bg-[#171717] shadow text-bb2 '
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                    )
                }
                >
                {season.title}
                </Tab>
            );
        })}

        </Tab.List>
        <Tab.Panels className="mt-2">
          {seasons.map((season, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl  p-3 m-0 ',
                'ring-white/60 ring-offset-2  focus:outline-none '
              )}
            >
              <ul>
                <EpisodeSelect anime_id={props.anime_id} season_id={season.id} season_title={season.title} />
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
