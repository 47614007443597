import React, { Component } from 'react';

import { BrowserRouter, Routes, Route  } from "react-router-dom";
import './css/App.css'

import Home from './pages/Home';
import View from './pages/View';
import Anime from './pages/Anime';
import Search from './pages/Search';
import About from './pages/About';

import Error from './components/Error';
import Footer from './components/Footer';

import Nav from './components/Nav';



class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { fallback } = this.props;

    if (hasError) {
      return React.cloneElement(fallback, { error, info });
    }

    return this.props.children;
  }
}


function App() {
  const setHtmlLangAttribute = () => {
    document.documentElement.lang = "fr";
  };
  setHtmlLangAttribute();

  const errorFallback = (
    <Error
      title="Erreur inattendue"
      text="Une erreur est survenue. Veuillez réessayer plus tard."
      button_text="Revenir à l'accueil"
      button_link="/"
    />
  );

  return (
    <ErrorBoundary fallback={errorFallback}>
      <>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/view/:id" element={<View />} />
          <Route path="/anime/:id" element={<Anime />} />
          <Route path="/about" element={<About />} />
          
          <Route path="/search/:type/:genre/:q" element={<Search />} />
          <Route path="/search/:type/:genre/" element={<Search />} />

          <Route path="/vf" element={<div><Nav /><Error error="Pas encore disponible" title="Découvrez notre liste d'anime VF trés prochainement" text="Cependant, vous pouvez regarder tous les épisodes de vos animes favoris en VOSTFR comme One Piece, et bien d'autres ci-dessous" button_text="Anime VostFR" button_link="/vostfr" /></div>} />
          
          <Route path='*' element={<Error error="Erreur 404" title="Tu t'es perdu ?" text="Cette page n'existe pas ou a été supprimée..." button_text="Revenir à l'accueil" button_link="/" />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </>
    </ErrorBoundary>
  );
}

export default App;