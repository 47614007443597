import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Nav from '../components/Nav';
import { useParams, Link } from 'react-router-dom';
import TrailerButton from '../components/openTrailer';
import SeasonSelect from '../components/SeasonSelector';
import DiscordCta from '../components/DiscordCta';
import { Helmet } from 'react-helmet';


export default function AnimeHeader() {

    function convertDate(dateString) {
        // Convertir la chaîne en objet Date
        const dateObject = new Date(dateString);
      
        // Options de formatage de date
        const options = { day: "numeric", month: "long", year: "numeric" };
      
        // Obtenir la date au format français
        const readableDate = dateObject.toLocaleDateString("fr-FR", options);
      
        return readableDate;
      }

      const { id } = useParams();
      const [animes, setAnime] = useState([]);
      const [stats, setStats] = useState([]);
      
      const anime_api = useMemo(() => ({
        method: 'GET',
        url: `${process.env.REACT_APP_FLEXAPI_URL}/animes`,
        params: {
          id: id 
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }), [id]);

      const stats_api = useMemo(() => ({
        method: 'GET',
        url: `${process.env.REACT_APP_FLEXAPI_URL}/stats/count`,
        params: {
          anime_id: id 
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }), [id]);
      
      useEffect(() => {
        axios.request(anime_api)
          .then((response) => {
              setAnime(response.data);
          })
          .catch((error) => {
            console.error('Error fetching episodes:', error);
          });
      }, [anime_api]);

      useEffect(() => {
        axios.request(stats_api)
          .then((response) => {
              setStats(response.data);
          })
          .catch((error) => {
            console.error('Error fetching stats:', error);
          });
      }, [stats_api]);
      
      useEffect(() => {
        document.title = `InuAnime`;
      }, []);

  return (

    <div>
 
        <Nav />
        {animes.map((anime) => (
        <>
        <Helmet>
          <title>{anime.title || "InuAnime"}</title>
          <meta name="description" content={anime.description || "Regarde ton anime gratuitement !"} />
          {/* OG tags */}
          <meta property="og:title" content={"InuAnime | " + anime.title} />
          <meta property="og:description" content={anime.description || "Regarde ton anime gratuitement !"} />
          <meta property="og:image" content={anime.image || ""} />
          <meta property="og:url" content={"https://inuanime.tv"} />
          <meta property="og:type" content="website" />
        </Helmet>
        <section className="header_anime" key={anime.id}>
          <div className="banniere bg-center" style={{
              backgroundImage: `url('${anime.banner}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              paddingBottom: '430px',
              backgroundPosition: 'center',
          }}>
            <div className="gradient-overlay"></div>
          </div>
        <div className="md:inline-flex lg:flex-nowrap flex-wrap flex flex-grow 2xl:mx-96 md:mx-40 mx-4 items-start">
            <div className="mx-auto -mt-40 flex md:flex-row flex-col flex-none">
            <div className="flex flex-col">
                <img className="w-64 h-auto imagepre shadow-xl shadow-indigo-500/40 z-20" src={anime.image} alt={anime.title + " - InuAnime, le meilleur site de streaming anime - VOSTFR, VF"} />

                <TrailerButton title={anime.title} title2={anime.title2} yt_id={anime.yt_code}/>
                <ul className='text-gray-400 py-4 px-1 text-center'>
                  <li>{stats.episode_count} épisodes</li> ・ 
                  <li>{stats.season_count} saisons</li>
                </ul>

            </div>
            </div>
            <div className="py-8 lg:px-12 px-4 md:flex-row flex-col">
            <h1 className="text-3xl font-bold">{anime.title}</h1>
            <p className=" text-gray-300 text-sm">{anime.title2}</p>
            <span className="text-xl">Sorti le <span className="text-bb font-bold nodarkmode">{convertDate(anime.date_time_published)}</span></span>
            <br />
            <span className="text-gray-500 ">Par <span className="text-bb font-bold nodarkmode">{anime.author}</span></span>
            <div className="pt-3">
                <div> 
                    <Link to={`/search/${anime.type}/all`} className="wh0 text-bb2 inline-flex items-center shadow-md px-2.5 py-0.5 rounded-md text-sm font-medium uppercase mr-1">
                    {anime.type}
                    </Link>
                    <Link to={`/search/all/${anime.genre}`} className="wh0 text-bb2 inline-flex items-center shadow-md px-2.5 py-0.5 rounded-md text-sm font-medium capitalize">
                    {anime.genre}
                    </Link>
                </div>
            </div>
            <p className="pt-3 text-gray-500 text-base nodarkmode2">
                {anime.description}
            </p>
            </div>
        </div>
          <SeasonSelect anime_id={anime.id}/>
        </section>
          </>
        ))}
        <DiscordCta />
    </div>
  );
};

