import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { MagnifyingGlassIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';

export default function SearchBar() {
  const [episodeCount, setEpisodeCount] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchEpisodeCount = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FLEXAPI_URL}/stats/count`);
        const data = await response.json();
        setEpisodeCount(data.episode_count);
      } catch (error) {
        console.error('Erreur lors de la récupération du nombre d\'épisodes:', error);
      }
    };

    fetchEpisodeCount();
  }, []); 

  const navigate = useNavigate();  
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/search/${selectedType || 'all'}/${selectedGenre || 'all'}/${searchTerm}`);
  };

  // Filter by genre
  const genres = ['all', 'action', 'seinen', 
                  'shonen', 'sport', 'fantasy', 
                  'comédie', 'horreur', 'ecchi', 
                  'psy', 'surnaturel', 'sliceoflife']; 
  const types = ['all', 'vostfr', 'vf']
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedType, setSelectedType] = useState(null);


  return (
    <div className='flex justify-center align-center z-40'>
      <form onSubmit={handleSubmit}>
        <div className="flex rounded-full bg-[#171717] px-2 py-2 sm:max-w-[600px] min-w-[320px] mx-4">
          <Popover className={"z-40"}>
            {({ open }) => (
              <>
                <Popover.Button className="flex p-1 cursor-pointer bg-[#171717] group ring-transparent	focus:ring-0 ring-0 mr-1">
                  <AdjustmentsHorizontalIcon className='h-6 self-center text-gray-400 group-hover:text-orange-500 group-focus:text-orange-300 translate-y-1' />
                </Popover.Button>
                <Popover.Panel className="absolute">
                  <div className='flex-row z-40 glass p-4 rounded-md mt-6'>
                    <h2 className='text-left pb-4 font-bold'>Filtrer par genre</h2>
                    <div className="grid md:grid-cols-4 grid-cols-3 gap-4">
                      {genres.map((genre) => (
                        <span
                          key={genre}
                          onClick={() => setSelectedGenre(genre)}
                          className={`${
                            selectedGenre === genre
                              ? 'bg-[#5B66C0] text-gray-200'
                              : 'bg-[#171717] text-[#5B66C0]'
                          } px-4 py-2 rounded-md cursor-pointer capitalize border-2 border-transparent hover:border-[#5B66C0]`}
                        >
                          {genre}
                        </span>
                      ))}
                    </div>

                    <h2 className='text-left pb-4 font-bold mt-4'>Filtrer par type</h2>
                    <div className="grid grid-cols-3 gap-4">
                    {types.map((type) => (
                        <span
                          key={type}
                          onClick={() => setSelectedType(type)}
                          className={`${
                            selectedType === type
                              ? 'bg-[#5B66C0] text-gray-200'
                              : 'bg-[#171717] text-[#5B66C0]'
                          } px-4 py-2 rounded-md cursor-pointer capitalize border-2 border-transparent hover:border-[#5B66C0]`}
                        >
                          {type}
                        </span>
                      ))}
                    </div>

                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>

          <input
            type="text"
            className="md:w-[800px] sm:w-[600px] min-w-[320px] glass flex bg-transparent pl-2 text-[#dddddd] outline-0"
            placeholder={`Recherche parmi ${episodeCount} épisodes sur InuAnime...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <button type="submit" className="relative p-2 bg-[#171717] rounded-full group duration-300">
            <MagnifyingGlassIcon className='h-6 text-gray-200 group-hover:text-[#5B66C0]' />
          </button>
        </div>
      </form>
    </div>
  );
}
